@import "./Variables.scss";
@import "./loader.scss";

@import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "../../../../node_modules/react-tabs/style/react-tabs.css";
@import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

body {
  //font-family: "Roboto", sans-serif;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
}
.react-datepicker__input-container, .react-datepicker-wrapper{
  display:block !important;
  width: 100%;
}
.novy-text-primary{
  color: $text-color !important;
}
.novy-btn-primary{
  background: $novy-recrute-primary;
  color: $light-text-color;
  &:hover{
    background: darken($novy-recrute-primary,10%);
    color: $light-text-color;
  }
  &:disabled{
    background: lighten($novy-recrute-primary,1%);
    color: $text-color;
    cursor: not-allowed;
    &:hover{
      //background: lighten($novy-recrute-secondary,10%);
      color: $text-color;
    }
  }
}

.novy-btn-primary-collab{
  background: $novy-collab-primary;
  color: $light-text-color;
  border: $novy-collab-primary;
  &:hover{
    background: darken($novy-collab-primary,10%);
    color: $light-text-color;
  }
  &:disabled{
    background: lighten($novy-collab-primary,1%);
    color: $text-color;
    cursor: not-allowed;
    &:hover{
      background: lighten($novy-collab-secondary,10%);
      color: $text-color;
    }
  }
  &:active{
    background: darken($novy-collab-primary,10%) !important;
    color: $light-text-color !important;
  }
}

hr.vertical-divider{
    border:         none;
    border-left:    1px solid $text-color;
    height:         100%;
    width:          1px;
}

.novy-btn-secondary{
  background: $novy-recrute-secondary;
  color: $light-text-color;
  &:hover{
    background: darken($novy-recrute-secondary,10%);
    color: $light-text-color;
  }
  &:disabled{
    background: lighten($novy-recrute-secondary,5%);
    color: $text-color;
    cursor: not-allowed;
    &:hover{
      //background: lighten($novy-recrute-secondary,10%);
      color: $text-color;
    }
  }
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: calc(100vh - 120px);
  background: #f6f8f9;
  margin-top: 66px;
}

.card{
  border-radius: 0.5rem;
}
.main {
  flex: 1 1;
  min-width: 0;
  margin-left: 230px;
  margin-top: 20px;
  margin-right: 10px;
  transition: margin-left .3s ease-in-out;
}

.profile-name{
  cursor: pointer;
}

@media (max-width: $screen-xs-max) {
  .main {
    flex: 1 1;
    min-width: 0;
    margin-left: 10px !important;
    margin-top: 10px;
    margin-right: 10px;
  }
  .app-body {
    margin-top: 50px;
  }
}
.novy_navbar_background{
  background: white;
}

nav.novy_navbar_background{
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
}

.novy-navbar {
  color: $novy-recrute-primary;
  &.novy_navbar_background{
    //box-shadow: 0 1px 10px $novy-recrute-header;
    border-top: 5px solid $novy-recrute-primary;
  }
  &-collab{
    color: $novy-collab-header;
    &.novy_navbar_background{
      //box-shadow: 0 1px 10px $novy-collab-header;
      border-top: 5px solid $novy-collab-header;
    }
  }
  &-admin{
    color: $novy-admin-header;
    &.novy_navbar_background{
      //box-shadow: 0 1px 10px $novy-admin-header;
      border-top: 5px solid $novy-admin-header;
    }
  }
  &-crm{
    color: $novy-crm-header;
    &.novy_navbar_background{
      //box-shadow: 0 1px 10px $novy-crm-header;
      border-top: 5px solid $novy-crm-header;
    }
  }
  &-supplier{
    color: $novy-supplier-header;
    &.novy_navbar_background{
      //box-shadow: 0 1px 10px $novy-supplier-header;
      border-top: 5px solid $novy-supplier-header;
    }
  }
  &-pay{
    color: $novy-pay-header;
    &.novy_navbar_background{
      //box-shadow: 0 1px 10px $novy-supplier-header;
      border-top: 5px solid $novy-pay-header;
    }
  }
}


.novy-sidebar {
  background-color: $background-color;
  height: calc(100vh - 62px);
  position: fixed;
  width: 230px;
  &.hide{
    width: 0;
    overflow: hidden;
    transition: width .3s ease-in-out;
  }

  &.show{
    @media (max-width: 767px) {
      background: $novy-background-color;
      box-shadow: 2px 0 5px 1px rgba(0,0,0,0.3);
    }
    &.novy_small_side_bar{
      background: transparent;
      box-shadow: none;
    }

    z-index: 1029;
    display: block !important;
    width: 230px;
    overflow: hidden;
    transition: width .3s ease-in-out;
  }
}

.novy-nav-item {
  border-left: 5px #fff solid;
  color: darken($novy-recrute-secondary, 10%);

  &:hover {
    background: #f6f8f9;
    color: $novy-recrute-secondary;
  }

  &.active {
    border-left: 5px $novy-recrute-primary solid;
    background: $novy-background-color;
  }
  &.active i {
    color: $novy-recrute-primary;
  }
}

.novy-nav-item-collab {
  text-align: center;
  border-left: 4px #fff solid;
  color: $novy-collab-secondary;

  &:hover {
    background: #f6f8f9;
    color: $novy-collab-secondary;
  }

  &.active {
    border-left: 4px $novy-collab-primary solid;
    background: $novy-background-color;
  }
  &.active i {
    color: $novy-collab-primary;
  }
}
.novy-nav-item-admin {
  text-align: center;
  border-left: 4px #fff solid;
  color: $novy-recrute-secondary;

  &:hover {
    background: #f6f8f9;
    color: $novy-recrute-secondary;
  }

  &.active {
    border-left: 4px $novy-recrute-primary-admin solid;
    background: $novy-background-color;
  }
  &.active i {
    color: $novy-recrute-primary-admin;
  }
}

.nav-dropdown .nav-dropdown-items {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  background: $novy-background-color;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

.nav-dropdown.open {
  .nav-dropdown-items {
    max-height: 1000px;
    background: #f6f8f9;
    transition: max-height 0.5s ease-in-out;
  }
  .novy-nav-item {
    background: $novy-background-color;
  }
}
.module_card{
  min-height: 120px;
  &:hover{
    background: $novy-background-color;
    cursor: pointer;
  }
}
.btn-novy-modules{
  color: $text-color;
  background: transparent;
  &:hover{
    color: darken($text-color, 20%);
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
.novy-header-nav-link {
  &:hover,
  &.active {
    background: $novy-background-color;
    cursor: pointer;
    border-radius: 0.5rem;
  }
  a {
    color: white;
    text-decoration: none;
    &.novy-color-primary{
      color: $text-color;
      &:hover{
        color: $text-color;
      }
    }
  }
  &-collab {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: $novy-collab-header-active;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  &-supplier {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  &-crm {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  &-admin {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  &-pay {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  &-recruitment {
    &:hover,
    &.active {
      background: $novy-background-color;
      cursor: pointer;
      border-radius: 0.5rem;
      a {
        color: white;
        text-decoration: none;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &.novy-color-primary{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
}




.apps_icons{
  font-size: 25px;
}

.novy-color-primary {
  color: $text-color;
  .inverse {
    color: $light-text-color;
  }
}

.novy-color-secondary {
  color: $novy-secondary-text-color;
  .inverse {
    color: white;
  }
}

.novy-shadow {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1) !important;
}
.eventTitle {
  font-size: 16px;
  color: $event-title-color;
}

.event-popover {
  width: 300px ;
}

.novy-validation-card{
  background-color: $novy-background-color;
}


.novy-cardtable-head {
  a {
    color: $novy-secondary-text-color;
    cursor: pointer;
    &:hover{
      color: $text-color;
    }
    &.active {
      background-color: transparent !important;
      color: $text-color;
      border: 0;
      border-bottom: 3px solid darkblue !important;
      &:hover{
        background-color: transparent;
        color: $text-color;
      }
    }
  }
}

.toggle-view-inactive{
  &:hover {
    background-color: #0069D9;
  }
}

.toggle-view-active{
  &:hover {
    background-color: #007BFF;
  }
}

.caption-valid{
  background-color: #2c3e50;
}

.caption-invalid-absence{
  background-color: #c0392b;
}

.caption-invalid-activity{
  background-color: #d35400;
}

.novy-wysiwyg .block-border  {
  border: 1px solid #e6e6e6 !important;
  padding: 5px;
  height: 250Px;
  width: 100%;
}


.react-datepicker-popper{
  z-index: 1000!important;
}

.react-phone-number-input__icon-image{
  height: 38px;

}

.react-phone-number-input__country{
  display: flex;
  .react-phone-number-input__country-select{
    width: 50%;
    flex-grow: 1;
  }
  select{
    max-width: 150px;
  }
}

.react-phone-number-input__icon{

  svg{
    width: 50px;
    height: 38px;
  }
}

.react-phone-number-input__row{
  height: 38px;
  width: 100%;
  display:flex;
  direction:row;
}

.focusProfile {
  background-color: transparent;
}

.focusProfile a {
  color: $novy-secondary-text-color !important;
}

.novy-input-mw85 {
  min-width: 85px;
}

.novy_small_side_bar {
  width:60px !important;
  @media only screen and (min-device-width : 320px) and (max-device-width : 576px) {
    width:0 !important;
  }
}
.profile-image{
  width:80px;
  height: 80px;
  border: 4px solid white;
  transition: width .2s ease-in, height .2s ease-in ;
  -moz-transition: width .2s ease-in, height .2s ease-in;
  -webkit-transition: width .2s ease-in, height .2s ease-in;
  box-shadow: 0 2px 15px 5px rgba(0,0,0,0.3);
  cursor: pointer;
  &-small{
    border: 3px solid white;
    width:40px;
    height: 40px;
    transition: width .2s ease-in, height .2s ease-in ;
    -moz-transition: width .2s ease-in, height .2s ease-in;
    -webkit-transition: width .2s ease-in, height .2s ease-in;
    box-shadow: 0 2px 15px 5px rgba(0,0,0,0.3);
    cursor: pointer;
  }
}

.novy_small_side_bar_name_closed {
  opacity:0;
  visibility: hidden;
  white-space: nowrap;
  font-max-size: 1px;
  overflow:hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear ;
  -moz-transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear;
  -webkit-transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear;
  //transition: opacity .3s ease-in-out;
}

.novy_small_side_bar_name_open {
  visibility: visible;
  opacity:1;
  transition: visibility .5s linear,opacity 1.5s ease-out;
  -moz-transition: visibility .5s linear, opacity 1.5s ease-out;
  -webkit-transition: visibility .5s linear, opacity 1.5s ease-out;
  //transition: opacity .3s ease-in-out;
}

.novy_small_side_bar_main {
  margin-left:50px !important;
  @media only screen and (min-device-width : 320px) and (max-device-width : 576px) {
    margin-left:0 !important;
  }
}

.btn-novy-sub_modules{
  color: $text-color !important;
  background: transparent;
  &:hover{
    color: darken($text-color, 20%) !important;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  font-weight: 100;

}


.btn-ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out .5s;
}

.btn-ripple::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: #000;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 1s;
  transform: scale(5, 5);
}

.btn-ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: .2;
  transition: 0s;
  transform: scale(0, 0);
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #f6f8f9 radial-gradient(circle, transparent 1%, #f6f8f9 1%) center/15000%;
}
.ripple:active {
  background-color: #e3e5e6;
  background-size: 100%;
  transition: background 0s;
}

.breadcrumb{
  background-color: transparent;
  &-collaborators a, h4{
    color: $novy-collab-primary;
  }
  &-recruitment a, h4{
    color: $novy-recrute-primary;
  }
  &-suppliers a, h4{
    color: $novy-supplier-header;
  }
  &-admin a, h4{
    color: $novy-admin-header;
  }
  &-crm a, h4{
    color: $novy-crm-header;
  }
  &-pay a, h4{
    color: $novy-pay-header;
  }
}
/*
h4.breadcrumb{
  background-color: transparent;
  &-collaborators{
    color: $novy-collab-primary;
  }
  &-recruitment{
    color: $novy-recrute-primary;
  }
  &-suppliers{
    color: $novy-supplier-header;
  }
  &-admin{
    color: $novy-admin-header;
  }
  &-crm {
    color: $novy-crm-header;
  }
}
*/

.novy-ellipsis-btn {
  border: 0 !important;
  background-color: inherit !important;
}

.novy-btn-rounded{
  border-radius: 30px !important;
}

.btn{
  &-supplier{
    background: $novy-supplier-header;
  }
  &-recruitment{
    background: $novy-recrute-primary;
  }
  &-crm{
    background: $novy-crm-header;
  }
  &-admin{
    background: $novy-admin-header;
  }
  &-collaborator{
    background: $novy-collab-primary;
  }
  &-pay{
    background: $novy-pay-header;
  }
}

.modal-content, .sweet-alert {
  border-radius: .55rem !important;
  border:0;
  top: 50px;
}

.novy-page-title{
  font-size: 25px;
  padding: .75rem 1rem .75rem 0;
  margin-bottom: 1rem;
  color: $novy-page-title-color;
}

.profile-profile-image{
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2) !important;
  object-fit: cover;
  border-radius: 50%;
}

.novy-stats-card{
  a:hover {
    text-decoration: none;
  }
}

button:disabled{
  color: white !important;
}