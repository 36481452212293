
.novy-calendar{
    overflow-x: scroll;
}

.ps__rail-x {
    z-index: 200
}

.ps__rail-y {
    z-index: 200
}


.toggle-container {
    position: relative;
}

.toggle-container .toggle-label {
    visibility: hidden;
    padding: 5px 15px 5px 0px; 
    position: absolute; 
    right: 80px;
    bottom: 0px;
    z-index: 100; 
    width: max-content; 
    text-align: center; 
    background-color: rgba(25,25,25,0.7);
    border-radius: 5px;
    color: white;
}

.toggle-container:hover .toggle-label{
    visibility: visible;
} 

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-thumb {
    background: #dbdbdb,
}

::-webkit-scrollbar-thumb:hover {
    background: #acacac,
}
