// TODO: move to novy-core

.novy {
    &-report-issue-btn {
        position: fixed;
        z-index: 1000;
        right: 0;
        border-radius: 20px 5px 5px 20px;
        padding-left: 1rem;
        bottom: 90px;
    }

    &-label-required:after {
        color: var(--danger);
        content: '*';
        padding-left: 5px;
    }
}

select {
    border-radius: 30px !important;
}
