@import "../Variables.scss";

.novy {
    &-ediText {
        &-saveButtonClassName {
        }
        &-editButtonClassName {
			outline: none;
			padding: 0.6em;
			min-width: 15px;
			height: 30px;
			width: 30px;
			border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
			border-style: solid;
			border-width: 1px;
			font-size : x-small;
			color: darkgreen;
			margin-right: 3px;
			}
		&-editButtonClassName :hover {
			background-color: rgba(204, 204, 204, 0.411);
        }
        &-editButtonClassName div:last-child {
    		background-color: #ccc;
        }

    }
}


.novy-ediText-editButtonClassName{
    margin-left: 1px;
    border-radius: 15px;
    font-size: medium;
    padding: 0;
    &:hover{
        background-color: $novy-collab-primary;
        color: white;
    }
    i{
        alignment: center;
        background: transparent;
    }
}
.styles_Editext__input__1534X{
    width: auto !important;
}
#departmentProfilContainer{
    width: 80% !important;
}

