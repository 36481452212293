@import "Variables.scss";

.novy-loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    top: 50%;
    position: relative;
  }
  .novy-loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid $novy-loader-color;
    border-color: $novy-loader-color transparent $novy-loader-color transparent;
    animation: novy-loader 1.2s linear infinite;
  }

.novy-loader-sm {
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 50%;
  position: relative;
}
.novy-loader-sm:after {
  content: " ";
  display: block;
  width: 23px;
  height: 23px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $novy-loader-color;
  border-color: $novy-loader-color transparent $novy-loader-color transparent;
  animation: novy-loader 1.2s linear infinite;
}
  @keyframes novy-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
