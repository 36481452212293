@import "./pilotageVariables.scss";

.novy-navbar-pilotage {
    color: $novy-pilotage-header;
}
.novy-nav-item-pilotage {
    text-align: center;
    border-left: 4px #fff solid;
    color: $novy-pilotage-secondary;
    &:hover {
        background: #F6F8F9;
        color: $novy-pilotage-secondary;
    }
    &.active {
        border-left: 4px $novy-pilotage-primary solid;
        background: $novy-background-color;
    }
    &.active i {
        color: $novy-pilotage-primary;
    }
}
.novy-btn-primary-pilotage{
    background: $novy-pilotage-primary;
    color: #F8F9FA;
    border: $novy-pilotage-primary;
    &:hover{
        background: darken($novy-pilotage-primary,10%);
        color: #F8F9FA;
    }
    &:disabled{
        background: lighten($novy-pilotage-primary,1%);
        color: #334E64;
        cursor: not-allowed;
        &:hover{
            background: lighten($novy-pilotage-secondary,10%);
            color: #334E64;
        }
    }
    &:active{
        background: darken($novy-pilotage-primary,10%) !important;
        color: #F8F9FA !important;
    }
}
.pt-45 {
    padding-top: 2.5rem!important;
}
