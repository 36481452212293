@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
.novy-report-issue-btn {
  position: fixed;
  z-index: 1000;
  right: 0;
  border-radius: 20px 5px 5px 20px;
  padding-left: 1rem;
  bottom: 90px; }

.novy-label-required:after {
  color: var(--danger);
  content: '*';
  padding-left: 5px; }

select {
  border-radius: 30px !important; }

.novy-ediText-editButtonClassName {
  outline: none;
  padding: 0.6em;
  min-width: 15px;
  height: 30px;
  width: 30px;
  border-color: #d8d8d8 #d1d1d1 #bababa;
  border-style: solid;
  border-width: 1px;
  font-size: x-small;
  color: darkgreen;
  margin-right: 3px; }

.novy-ediText-editButtonClassName :hover {
  background-color: rgba(204, 204, 204, 0.411); }

.novy-ediText-editButtonClassName div:last-child {
  background-color: #ccc; }

.novy-ediText-editButtonClassName {
  margin-left: 1px;
  border-radius: 15px;
  font-size: medium;
  padding: 0; }
  .novy-ediText-editButtonClassName:hover {
    background-color: #c0392b;
    color: white; }
  .novy-ediText-editButtonClassName i {
    alignment: center;
    background: transparent; }

.styles_Editext__input__1534X {
  width: auto !important; }

#departmentProfilContainer {
  width: 80% !important; }

.novy-calendar {
  overflow-x: scroll; }

.ps__rail-x {
  z-index: 200; }

.ps__rail-y {
  z-index: 200; }

.toggle-container {
  position: relative; }

.toggle-container .toggle-label {
  visibility: hidden;
  padding: 5px 15px 5px 0px;
  position: absolute;
  right: 80px;
  bottom: 0px;
  z-index: 100;
  width: max-content;
  text-align: center;
  background-color: rgba(25, 25, 25, 0.7);
  border-radius: 5px;
  color: white; }

.toggle-container:hover .toggle-label {
  visibility: visible; }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-thumb {
  background: #dbdbdb; }

::-webkit-scrollbar-thumb:hover {
  background: #acacac; }

.novy-editInput-editInputClassName {
  outline: none;
  padding: 0.6em;
  min-width: 15px;
  /* height: 30px; */
  width: 80px;
  border-color: #d8d8d8 #d1d1d1 #bababa;
  border-style: solid;
  border-width: 1px;
  font-size: small;
  margin-right: 3px;
  z-index: 1000; }

.loader-container {
  width: 60px;
  height: 60px;
  position: relative; }
  .loader-container.animation-1 {
    transform: rotate(45deg); }
  .loader-container.animation-2, .loader-container.animation-4 {
    transform: rotate(10deg); }
    .loader-container.animation-2 .shape, .loader-container.animation-4 .shape {
      border-radius: 7.5px; }
  .loader-container.animation-2, .loader-container.animation-3, .loader-container.animation-4 {
    animation: rotation 1s infinite; }
  .loader-container.animation-3 .shape1 {
    border-top-left-radius: 15px; }
  .loader-container.animation-3 .shape2 {
    border-top-right-radius: 15px; }
  .loader-container.animation-3 .shape3 {
    border-bottom-left-radius: 15px; }
  .loader-container.animation-3 .shape4 {
    border-bottom-right-radius: 15px; }
  .loader-container.animation-4, .loader-container.animation-5 {
    transform: rotate(45deg); }
  .loader-container.animation-5 .shape {
    width: 15px;
    height: 15px; }
  .loader-container.animation-6 {
    animation: rotation 1s infinite; }
    .loader-container.animation-6 .shape {
      width: 12px;
      height: 12px;
      border-radius: 2px; }
  .loader-container .shape {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 1px; }
    .loader-container .shape.shape1 {
      left: 0;
      background-color: #0098D5; }
    .loader-container .shape.shape2 {
      right: 0;
      background-color: #1abc9c; }
    .loader-container .shape.shape3 {
      bottom: 0;
      background-color: #e67e22; }
    .loader-container .shape.shape4 {
      bottom: 0;
      right: 0;
      background-color: #d35400; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.animation-1 .shape1 {
  animation: animation1shape1 0.5s ease 0s infinite alternate; }

@keyframes animation1shape1 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(16px, 16px); } }

.animation-1 .shape2 {
  animation: animation1shape2 0.5s ease 0s infinite alternate; }

@keyframes animation1shape2 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-16px, 16px); } }

.animation-1 .shape3 {
  animation: animation1shape3 0.5s ease 0s infinite alternate; }

@keyframes animation1shape3 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(16px, -16px); } }

.animation-1 .shape4 {
  animation: animation1shape4 0.5s ease 0s infinite alternate; }

@keyframes animation1shape4 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-16px, -16px); } }

.animation-2 .shape1 {
  animation: animation2shape1 0.5s ease 0s infinite alternate; }

@keyframes animation2shape1 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(20px, 20px); } }

.animation-2 .shape2 {
  animation: animation2shape2 0.5s ease 0s infinite alternate; }

@keyframes animation2shape2 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-20px, 20px); } }

.animation-2 .shape3 {
  animation: animation2shape3 0.5s ease 0s infinite alternate; }

@keyframes animation2shape3 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(20px, -20px); } }

.animation-2 .shape4 {
  animation: animation2shape4 0.5s ease 0s infinite alternate; }

@keyframes animation2shape4 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-20px, -20px); } }

.animation-3 .shape1 {
  animation: animation3shape1 0.5s ease 0s infinite alternate; }

@keyframes animation3shape1 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(5px, 5px); } }

.animation-3 .shape2 {
  animation: animation3shape2 0.5s ease 0s infinite alternate; }

@keyframes animation3shape2 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-5px, 5px); } }

.animation-3 .shape3 {
  animation: animation3shape3 0.5s ease 0s infinite alternate; }

@keyframes animation3shape3 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(5px, -5px); } }

.animation-3 .shape4 {
  animation: animation3shape4 0.5s ease 0s infinite alternate; }

@keyframes animation3shape4 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-5px, -5px); } }

.animation-4 .shape1 {
  animation: animation4shape1 0.3s ease 0s infinite alternate; }

@keyframes animation4shape1 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(5px, 5px); } }

.animation-4 .shape2 {
  animation: animation4shape2 0.3s ease 0.3s infinite alternate; }

@keyframes animation4shape2 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-5px, 5px); } }

.animation-4 .shape3 {
  animation: animation4shape3 0.3s ease 0.3s infinite alternate; }

@keyframes animation4shape3 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(5px, -5px); } }

.animation-4 .shape4 {
  animation: animation4shape4 0.3s ease 0s infinite alternate; }

@keyframes animation4shape4 {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-5px, -5px); } }

.animation-5 .shape1 {
  animation: animation5shape1 2s ease 0s infinite reverse; }

@keyframes animation5shape1 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(0, 15px); }
  50% {
    transform: translate(15px, 15px); }
  75% {
    transform: translate(15px, 0); } }

.animation-5 .shape2 {
  animation: animation5shape2 2s ease 0s infinite reverse; }

@keyframes animation5shape2 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(-15px, 0); }
  50% {
    transform: translate(-15px, 15px); }
  75% {
    transform: translate(0, 15px); } }

.animation-5 .shape3 {
  animation: animation5shape3 2s ease 0s infinite reverse; }

@keyframes animation5shape3 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(15px, 0); }
  50% {
    transform: translate(15px, -15px); }
  75% {
    transform: translate(0, -15px); } }

.animation-5 .shape4 {
  animation: animation5shape4 2s ease 0s infinite reverse; }

@keyframes animation5shape4 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(0, -15px); }
  50% {
    transform: translate(-15px, -15px); }
  75% {
    transform: translate(-15px, 0); } }

.animation-6 .shape1 {
  animation: animation6shape1 2s linear 0s infinite normal; }

@keyframes animation6shape1 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(0, 18px); }
  50% {
    transform: translate(18px, 18px); }
  75% {
    transform: translate(18px, 0); } }

.animation-6 .shape2 {
  animation: animation6shape2 2s linear 0s infinite normal; }

@keyframes animation6shape2 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(-18px, 0); }
  50% {
    transform: translate(-18px, 18px); }
  75% {
    transform: translate(0, 18px); } }

.animation-6 .shape3 {
  animation: animation6shape3 2s linear 0s infinite normal; }

@keyframes animation6shape3 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(18px, 0); }
  50% {
    transform: translate(18px, -18px); }
  75% {
    transform: translate(0, -18px); } }

.animation-6 .shape4 {
  animation: animation6shape4 2s linear 0s infinite normal; }

@keyframes animation6shape4 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(0, -18px); }
  50% {
    transform: translate(-18px, -18px); }
  75% {
    transform: translate(-18px, 0); } }

.novy-loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  top: 50%;
  position: relative; }

.novy-loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #324E64;
  border-color: #324E64 transparent #324E64 transparent;
  animation: novy-loader 1.2s linear infinite; }

.novy-loader-sm {
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 50%;
  position: relative; }

.novy-loader-sm:after {
  content: " ";
  display: block;
  width: 23px;
  height: 23px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #324E64;
  border-color: #324E64 transparent #324E64 transparent;
  animation: novy-loader 1.2s linear infinite; }

@keyframes novy-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

body {
  font-family: 'Montserrat', Arial, Tahoma, sans-serif; }

.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important;
  width: 100%; }

.novy-text-primary {
  color: #334E64 !important; }

.novy-btn-primary {
  background: #0098D5;
  color: #f8f9fa; }
  .novy-btn-primary:hover {
    background: #0074a2;
    color: #f8f9fa; }
  .novy-btn-primary:disabled {
    background: #009cda;
    color: #334E64;
    cursor: not-allowed; }
    .novy-btn-primary:disabled:hover {
      color: #334E64; }

.novy-btn-primary-collab {
  background: #c0392b;
  color: #f8f9fa;
  border: #c0392b; }
  .novy-btn-primary-collab:hover {
    background: #962d22;
    color: #f8f9fa; }
  .novy-btn-primary-collab:disabled {
    background: #c43a2c;
    color: #334E64;
    cursor: not-allowed; }
    .novy-btn-primary-collab:disabled:hover {
      background: #acb7c0;
      color: #334E64; }
  .novy-btn-primary-collab:active {
    background: #962d22 !important;
    color: #f8f9fa !important; }

hr.vertical-divider {
  border: none;
  border-left: 1px solid #334E64;
  height: 100%;
  width: 1px; }

.novy-btn-secondary {
  background: #8F9EAA;
  color: #f8f9fa; }
  .novy-btn-secondary:hover {
    background: #728594;
    color: #f8f9fa; }
  .novy-btn-secondary:disabled {
    background: #9dabb5;
    color: #334E64;
    cursor: not-allowed; }
    .novy-btn-secondary:disabled:hover {
      color: #334E64; }

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: calc(100vh - 120px);
  background: #f6f8f9;
  margin-top: 66px; }

.card {
  border-radius: 0.5rem; }

.main {
  flex: 1 1;
  min-width: 0;
  margin-left: 230px;
  margin-top: 20px;
  margin-right: 10px;
  transition: margin-left .3s ease-in-out; }

.profile-name {
  cursor: pointer; }

@media (max-width: 767px) {
  .main {
    flex: 1 1;
    min-width: 0;
    margin-left: 10px !important;
    margin-top: 10px;
    margin-right: 10px; }
  .app-body {
    margin-top: 50px; } }

.novy_navbar_background {
  background: white; }

nav.novy_navbar_background {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

.novy-navbar {
  color: #0098D5; }
  .novy-navbar.novy_navbar_background {
    border-top: 5px solid #0098D5; }
  .novy-navbar-collab {
    color: #c0392b; }
    .novy-navbar-collab.novy_navbar_background {
      border-top: 5px solid #c0392b; }
  .novy-navbar-admin {
    color: #1abc9c; }
    .novy-navbar-admin.novy_navbar_background {
      border-top: 5px solid #1abc9c; }
  .novy-navbar-crm {
    color: #d35400; }
    .novy-navbar-crm.novy_navbar_background {
      border-top: 5px solid #d35400; }
  .novy-navbar-supplier {
    color: #8e44ad; }
    .novy-navbar-supplier.novy_navbar_background {
      border-top: 5px solid #8e44ad; }
  .novy-navbar-pay {
    color: #e84393; }
    .novy-navbar-pay.novy_navbar_background {
      border-top: 5px solid #e84393; }

.novy-sidebar {
  background-color: #f6f8f9;
  height: calc(100vh - 62px);
  position: fixed;
  width: 230px; }
  .novy-sidebar.hide {
    width: 0;
    overflow: hidden;
    transition: width .3s ease-in-out; }
  .novy-sidebar.show {
    z-index: 1029;
    display: block !important;
    width: 230px;
    overflow: hidden;
    transition: width .3s ease-in-out; }
    @media (max-width: 767px) {
      .novy-sidebar.show {
        background: #F6F8F9;
        box-shadow: 2px 0 5px 1px rgba(0, 0, 0, 0.3); } }
    .novy-sidebar.show.novy_small_side_bar {
      background: transparent;
      box-shadow: none; }

.novy-nav-item {
  border-left: 5px #fff solid;
  color: #728594; }
  .novy-nav-item:hover {
    background: #f6f8f9;
    color: #8F9EAA; }
  .novy-nav-item.active {
    border-left: 5px #0098D5 solid;
    background: #F6F8F9; }
  .novy-nav-item.active i {
    color: #0098D5; }

.novy-nav-item-collab {
  text-align: center;
  border-left: 4px #fff solid;
  color: #8F9EAA; }
  .novy-nav-item-collab:hover {
    background: #f6f8f9;
    color: #8F9EAA; }
  .novy-nav-item-collab.active {
    border-left: 4px #c0392b solid;
    background: #F6F8F9; }
  .novy-nav-item-collab.active i {
    color: #c0392b; }

.novy-nav-item-admin {
  text-align: center;
  border-left: 4px #fff solid;
  color: #8F9EAA; }
  .novy-nav-item-admin:hover {
    background: #f6f8f9;
    color: #8F9EAA; }
  .novy-nav-item-admin.active {
    border-left: 4px #55efc4 solid;
    background: #F6F8F9; }
  .novy-nav-item-admin.active i {
    color: #55efc4; }

.nav-dropdown .nav-dropdown-items {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  background: #F6F8F9;
  transition: max-height 0.3s ease-in-out; }

.nav-dropdown.open .nav-dropdown-items {
  max-height: 1000px;
  background: #f6f8f9;
  transition: max-height 0.5s ease-in-out; }

.nav-dropdown.open .novy-nav-item {
  background: #F6F8F9; }

.module_card {
  min-height: 120px; }
  .module_card:hover {
    background: #F6F8F9;
    cursor: pointer; }

.btn-novy-modules {
  color: #334E64;
  background: transparent; }
  .btn-novy-modules:hover {
    color: #111920;
    cursor: pointer; }
  .btn-novy-modules:focus {
    outline: none; }

.novy-header-nav-link:hover, .novy-header-nav-link.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }

.novy-header-nav-link a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-collab:hover, .novy-header-nav-link-collab.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-collab:hover a, .novy-header-nav-link-collab.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-collab a {
  color: #962d22;
  text-decoration: none; }
  .novy-header-nav-link-collab a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-collab a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-supplier:hover, .novy-header-nav-link-supplier.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-supplier:hover a, .novy-header-nav-link-supplier.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-supplier a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link-supplier a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-supplier a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-crm:hover, .novy-header-nav-link-crm.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-crm:hover a, .novy-header-nav-link-crm.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-crm a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link-crm a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-crm a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-admin:hover, .novy-header-nav-link-admin.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-admin:hover a, .novy-header-nav-link-admin.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-admin a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link-admin a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-admin a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-pay:hover, .novy-header-nav-link-pay.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-pay:hover a, .novy-header-nav-link-pay.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-pay a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link-pay a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-pay a.novy-color-primary:hover {
      color: #334E64; }

.novy-header-nav-link-recruitment:hover, .novy-header-nav-link-recruitment.active {
  background: #F6F8F9;
  cursor: pointer;
  border-radius: 0.5rem; }
  .novy-header-nav-link-recruitment:hover a, .novy-header-nav-link-recruitment.active a {
    color: white;
    text-decoration: none; }

.novy-header-nav-link-recruitment a {
  color: white;
  text-decoration: none; }
  .novy-header-nav-link-recruitment a.novy-color-primary {
    color: #334E64; }
    .novy-header-nav-link-recruitment a.novy-color-primary:hover {
      color: #334E64; }

.apps_icons {
  font-size: 25px; }

.novy-color-primary {
  color: #334E64; }
  .novy-color-primary .inverse {
    color: #f8f9fa; }

.novy-color-secondary {
  color: #8F9EAA; }
  .novy-color-secondary .inverse {
    color: white; }

.novy-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important; }

.eventTitle {
  font-size: 16px;
  color: #E9EBEB; }

.event-popover {
  width: 300px; }

.novy-validation-card {
  background-color: #F6F8F9; }

.novy-cardtable-head a {
  color: #8F9EAA;
  cursor: pointer; }
  .novy-cardtable-head a:hover {
    color: #334E64; }
  .novy-cardtable-head a.active {
    background-color: transparent !important;
    color: #334E64;
    border: 0;
    border-bottom: 3px solid darkblue !important; }
    .novy-cardtable-head a.active:hover {
      background-color: transparent;
      color: #334E64; }

.toggle-view-inactive:hover {
  background-color: #0069D9; }

.toggle-view-active:hover {
  background-color: #007BFF; }

.caption-valid {
  background-color: #2c3e50; }

.caption-invalid-absence {
  background-color: #c0392b; }

.caption-invalid-activity {
  background-color: #d35400; }

.novy-wysiwyg .block-border {
  border: 1px solid #e6e6e6 !important;
  padding: 5px;
  height: 250Px;
  width: 100%; }

.react-datepicker-popper {
  z-index: 1000 !important; }

.react-phone-number-input__icon-image {
  height: 38px; }

.react-phone-number-input__country {
  display: flex; }
  .react-phone-number-input__country .react-phone-number-input__country-select {
    width: 50%;
    flex-grow: 1; }
  .react-phone-number-input__country select {
    max-width: 150px; }

.react-phone-number-input__icon svg {
  width: 50px;
  height: 38px; }

.react-phone-number-input__row {
  height: 38px;
  width: 100%;
  display: flex;
  direction: row; }

.focusProfile {
  background-color: transparent; }

.focusProfile a {
  color: #8F9EAA !important; }

.novy-input-mw85 {
  min-width: 85px; }

.novy_small_side_bar {
  width: 60px !important; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .novy_small_side_bar {
      width: 0 !important; } }

.profile-image {
  width: 80px;
  height: 80px;
  border: 4px solid white;
  transition: width .2s ease-in, height .2s ease-in;
  -moz-transition: width .2s ease-in, height .2s ease-in;
  -webkit-transition: width .2s ease-in, height .2s ease-in;
  box-shadow: 0 2px 15px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer; }
  .profile-image-small {
    border: 3px solid white;
    width: 40px;
    height: 40px;
    transition: width .2s ease-in, height .2s ease-in;
    -moz-transition: width .2s ease-in, height .2s ease-in;
    -webkit-transition: width .2s ease-in, height .2s ease-in;
    box-shadow: 0 2px 15px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer; }

.novy_small_side_bar_name_closed {
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  font-max-size: 1px;
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear;
  -moz-transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear;
  -webkit-transition: visibility 1s linear, opacity .5s ease-in, position 1.2s linear, top 1.2s linear, left 1.2s linear; }

.novy_small_side_bar_name_open {
  visibility: visible;
  opacity: 1;
  transition: visibility .5s linear,opacity 1.5s ease-out;
  -moz-transition: visibility .5s linear, opacity 1.5s ease-out;
  -webkit-transition: visibility .5s linear, opacity 1.5s ease-out; }

.novy_small_side_bar_main {
  margin-left: 50px !important; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .novy_small_side_bar_main {
      margin-left: 0 !important; } }

.btn-novy-sub_modules {
  color: #334E64 !important;
  background: transparent;
  font-weight: 100; }
  .btn-novy-sub_modules:hover {
    color: #111920 !important;
    cursor: pointer; }
  .btn-novy-sub_modules:focus {
    outline: none; }

.btn-ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out .5s; }

.btn-ripple::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: #000;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 1s;
  transform: scale(5, 5); }

.btn-ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: .2;
  transition: 0s;
  transform: scale(0, 0); }

.ripple {
  background-position: center;
  transition: background 0.8s; }

.ripple:hover {
  background: #f6f8f9 radial-gradient(circle, transparent 1%, #f6f8f9 1%) center/15000%; }

.ripple:active {
  background-color: #e3e5e6;
  background-size: 100%;
  transition: background 0s; }

.breadcrumb {
  background-color: transparent; }
  .breadcrumb-collaborators a, .breadcrumb h4 {
    color: #c0392b; }
  .breadcrumb-recruitment a, .breadcrumb h4 {
    color: #0098D5; }
  .breadcrumb-suppliers a, .breadcrumb h4 {
    color: #8e44ad; }
  .breadcrumb-admin a, .breadcrumb h4 {
    color: #1abc9c; }
  .breadcrumb-crm a, .breadcrumb h4 {
    color: #d35400; }
  .breadcrumb-pay a, .breadcrumb h4 {
    color: #e84393; }

/*
h4.breadcrumb{
  background-color: transparent;
  &-collaborators{
    color: $novy-collab-primary;
  }
  &-recruitment{
    color: $novy-recrute-primary;
  }
  &-suppliers{
    color: $novy-supplier-header;
  }
  &-admin{
    color: $novy-admin-header;
  }
  &-crm {
    color: $novy-crm-header;
  }
}
*/
.novy-ellipsis-btn {
  border: 0 !important;
  background-color: inherit !important; }

.novy-btn-rounded {
  border-radius: 30px !important; }

.btn-supplier {
  background: #8e44ad; }

.btn-recruitment {
  background: #0098D5; }

.btn-crm {
  background: #d35400; }

.btn-admin {
  background: #1abc9c; }

.btn-collaborator {
  background: #c0392b; }

.btn-pay {
  background: #e84393; }

.modal-content, .sweet-alert {
  border-radius: .55rem !important;
  border: 0;
  top: 50px; }

.novy-page-title {
  font-size: 25px;
  padding: .75rem 1rem .75rem 0;
  margin-bottom: 1rem;
  color: #6c757d; }

.profile-profile-image {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2) !important;
  object-fit: cover;
  border-radius: 50%; }

.novy-stats-card a:hover {
  text-decoration: none; }

button:disabled {
  color: white !important; }

.novy-navbar-pilotage {
  color: #5C500F; }

.novy-nav-item-pilotage {
  text-align: center;
  border-left: 4px #fff solid;
  color: #8F9EAA; }
  .novy-nav-item-pilotage:hover {
    background: #F6F8F9;
    color: #8F9EAA; }
  .novy-nav-item-pilotage.active {
    border-left: 4px #5C500F solid;
    background: #F6F8F9; }
  .novy-nav-item-pilotage.active i {
    color: #5C500F; }

.novy-btn-primary-pilotage {
  background: #5C500F;
  color: #F8F9FA;
  border: #5C500F; }
  .novy-btn-primary-pilotage:hover {
    background: #302a08;
    color: #F8F9FA; }
  .novy-btn-primary-pilotage:disabled {
    background: #605410;
    color: #334E64;
    cursor: not-allowed; }
    .novy-btn-primary-pilotage:disabled:hover {
      background: #acb7c0;
      color: #334E64; }
  .novy-btn-primary-pilotage:active {
    background: #302a08 !important;
    color: #F8F9FA !important; }

.pt-45 {
  padding-top: 2.5rem !important; }

