$novy-supplier-primary: #8e44ad;
$novy-recrute-primary: #0098D5;
$novy-recrute-secondary: #8F9EAA;
$novy-recrute-header: #324E64;
$novy-recrute-header-active: #193348;
$novy-background-color: #F6F8F9;
$text-color: #334E64;
$novy-secondary-text-color:#8F9EAA;
$light-text-color:#f8f9fa;
$background-color:#f6f8f9;
$darken-light-color : darken($light-text-color,3%);
$novy-loader-color : #324E64;

$event-title-color : #E9EBEB;
$event-valid-color : #1fe1b6;
$event-invalid-color : #FA9E95;

$novy-collab-primary: #c0392b;
$novy-collab-secondary: #8F9EAA;
$novy-collab-header: #c0392b;
$novy-collab-header-active: darken($novy-collab-header, 10%);

$novy-admin-header: #1abc9c;
$novy-admin-header-active: #16a085;


$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

$novy-recrute-primary-admin: #55efc4;


$novy-recrute-primary: #0098D5;
$novy-recrute-secondary: #8F9EAA;
$novy-recrute-header: #324E64;
$novy-recrute-header-active: #193348;
$novy-background-color: #F6F8F9;
$text-color: #334E64;
$novy-secondary-text-color:#8F9EAA;
$light-text-color:#f8f9fa;
$background-color:#f6f8f9;
$darken-light-color : darken($light-text-color,3%);
$novy-loader-color : #324E64;

$event-title-color : #E9EBEB;

$novy-page-title-color: #6c757d;

$novy-input-border: #E3E3E3;

$novy-collab-primary: #c0392b;
$novy-collab-secondary: #8F9EAA;
$novy-collab-header: #c0392b;
$novy-collab-header-active: darken($novy-collab-header, 10%);

$novy-admin-header: #1abc9c;
$novy-admin-header-active: #16a085;

$novy-crm-header: #d35400;
$novy-crm-header-active: darken($novy-crm-header, 10%);

$novy-supplier-header: #8e44ad;
$novy-supplier-header-active: darken($novy-supplier-header, 10%);

$novy-pay-header: #e84393;
$novy-pay-header-active: darken($novy-pay-header, 10%);

$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

$novy-recrute-primary-admin: #55efc4;
