@import "../Variables.scss";

.novy {
    &-editInput {
        &-editInputClassName {
			outline: none;
			padding: 0.6em;
			min-width: 15px;
			/* height: 30px; */
			width: 80px;
			border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
			border-style: solid;
			border-width: 1px;
            font-size : small;
            margin-right: 3px;
            z-index: 1000;
			}
    }
}




